import React from 'react';
import { useSelector } from 'react-redux';
import {
  useHistory
} from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { addTokenToURL } from '~/apiCalls/authApi';
import FilterGallery from './FilterGallery';
import getTransText from '~~/get-text/getTransText';


export default function ProfileContent(profileContentId, index, openLightboxOnSlide) {
  const imgSrc = profileContentId ? addTokenToURL(`${process.env.REACT_APP_API_URL}file-access/${profileContentId}?width=335&height=250`) : "";
  const history = useHistory();

  const {
    isMe,
  } = useSelector(state => state.profile);

  return (
    <>
      <div className="profile__tab__top">
        {
          (isMe) ? (
            <>
              <div className="no-photo">
                <span className="icon"><i className="las la-user-alt"></i></span>
                <p>{getTransText("Answer some questions to complete your profile!")}</p>
                <ButtonGroup>
                  <Button color="primary" onClick={() => { history.push(`/editProfileSetting`); }}>{getTransText("profile.edit.profile")}<i className="las la-pen"></i></Button>
                </ButtonGroup>
              </div>
            </>
          )
            : ""
        }

      </div>
      <div className="profile__tab__item">
        <div className="profile__tab__item__header">{getTransText("profile.tab.profile.gallery")}</div>
        <FilterGallery />
      </div>
      <div className="profile__tab__item">
        <div className="profile__tab__item__header">Which types of requests do I accept?</div>
        <ListGroup horizontal>
          <ListGroupItem>
            Heterosexual
          </ListGroupItem>
          <ListGroupItem>
            Homosexual
          </ListGroupItem>
          <ListGroupItem>
            Bisexual
          </ListGroupItem>
        </ListGroup>
      </div>
      <div className="profile__tab__item">
        <div className="profile__tab__item__header">What is the minimum starting price I accept for my content?</div>
        <ListGroup horizontal>
          <ListGroupItem>
            <div className="quo__badge">
              <i className="quo__badge-bl200"></i>
              <div className="quo__price">$100</div>
            </div>
          </ListGroupItem>
        </ListGroup>
      </div>
    </>
  );
}
