import React from 'react';
import { useSelector } from 'react-redux';
import NewProduct from '~~/marketplace/NewProduct';

const ProfileContainer = () => {
  const {
    authenticated,
  } = useSelector(state => state.auth);

  return (
    <>
      {authenticated ? (
        <>
          <NewProduct />
        </>
      ) : (
        <>
          <div className="container__wrap nosidebar">
            <NewProduct />
          </div>
        </>
      )}
    </>
  );
};


export default ProfileContainer;
