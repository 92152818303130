import React, { useEffect, useState } from 'react';
import CookieConsent from "react-cookie-consent";
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  Link, Redirect, Route,
  Switch, useHistory, useLocation
} from 'react-router-dom';
import { getWebTextByLang } from '~/apiCalls/uiApi';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import { refresh } from '~/apiCalls/authApi';
import { apiCallBegan } from '~/store/api';
import { authRefreshed, authRefreshFailed } from '~/store/auth';
import { changedLangCode, uiTextLoaded, uiTextLoadError, accepted18 } from '~/store/ui';
import OutreachCheckoutProvider from '~~/checkout-form/OutreachCheckoutProvider';
import CheckoutProvider from '~~/checkout-form/CheckoutProvider';
import EmailSent from '~~/checkout-form/EmailVerification';
import getTransText from '~~/get-text/getTransText';
import LogoutAuth from '~~/logout/LogoutAuth';
import RefreshTokenFromCookie from '~~/RefreshTokenFromCookie';
import TopbarNoAuth from '~~/topbarNoAuth/Topbar';
import TopbarInfo from '~~/topbarInfo/Topbar';
import useRendersCount from '~~/render_log';
import AccountSetting from '../AccountSetting';
import EditProfileSetting from '../EditProfileSetting';
import BookmarkedProfile from '../BookmarkedProfile/index';
import Chat from '../Chat/index';
import ErrorPage from '../Default/404';
import ProfileFrom from '../EditProfile/index';
import Example from '../Example/verify';
import FAQ from '../FAQ';
import HowtoStartPage from '../HowToStart';
// import Landing from '../LandingBuyingSelling/index';
import Landing from '../LandingBuyer/index';
import LandingSeller from '../LandingSeller/index';
import InvitingQuoForCreator from '../InvitingQuoForCreator';
import Layout from '../Layout/index';
import LogIn from '../LogIn/index';
import MQG from '../ManageQuo/components/MQG';
import QuoSearchingActivity from '../ManageQuo/components/QuoSearchingActivity';
import QuoHistory from '../ManageQuo/components/QuoHistory';
import ManageQuo from '../ManageQuo/index';
import OpenDetail from '../OpenCall/components/OpenDetail';
import OpenCall from '../OpenCall/index';
import MyUploadedGallery from '../MyUploadedGallery/index';
import PickSuccess from '../SuccessPage';
import PaySuccess from '../PaySuccess';
import GuestPaySuccess from '../PaySuccess/GuestPaySuccess';
import CookiePolicy from '../Policy/CookiePolicy';
import PrivacyPolicy from '../Policy/PrivacyPolicy';
import Terms from '../Policy/Terms';
import Profile from '../Profile/index';
import PublicProfile from '../PublicProfile/index';
import AcceptInvite from '../Register/accept.jsx';
import ForgetPassword from '../Register/ForgetPassword';
import Register from '../Register/index';
import RegSuccess from '../Register/RegSuccess';
import RequestSuccess from '../Register/RequestSuccess';
import ResetPassword from '../Register/ResetPassword';
import ResetSuccess from '../Register/ResetSuccess';
import SetUsername from '../SetUsername';
import Dashboard from '../Wallet/index';
import MainWrapper from './MainWrapper';
import { loggingOutreachPayment } from '~/store/redirect';
import GetVerified from '../Profile/GetVerified';
import OpenDetailDirectPage from '../OpenCall/components/OpenDetailDirectPage';
import RedeemSuccess from '../PaySuccess/redeemSuccess';
import QuoFormAdmin from '~~/quo_form/QuoFormAdmin';



const Pages = () => {
  const {
    modal
  } = useSelector(state => state.opencall);
  return (
    <Switch>
      <Route path="/quo-admin" component={QuoFormAdmin} />
      <Route path="/get-verified" component={GetVerified} />
      {!modal && <Route exact path="/opencall/:quo_id" component={OpenDetailDirectPage} />}
      <Route exact path="/redeemSuccess" component={RedeemSuccess} />
      <Route exact path="/iq/:quoId" component={InvitingQuoForCreator} />
      <Route path="/opencall/demo/:page" component={OpenCall} />
      <Route path="/opencall" component={OpenCall} />
      <Route path="/p/:username" component={Profile} />
      <Route exact path="/how-to" component={HowtoStartPage} />
      <Route exact path="/m/" component={MQG} />
      <Route path="/manageQuo/:page" component={ManageQuo} />
      <Route path="/manageQuo" component={ManageQuo} />
      <Route path="/myupload" component={MyUploadedGallery} />
      <Route path="/editProfile" component={ProfileFrom} />
      <Route path="/editProfileSetting" component={EditProfileSetting} />
      <Route path="/accountSetting/:tabActive" component={AccountSetting} />
      <Route path="/accountSetting" component={AccountSetting} />
      <Route path="/bookmarks" component={BookmarkedProfile} />
      <Route path="/oq/:quo_id" component={QuoSearchingActivity} />
      <Route path="/q/:quo_id" component={QuoHistory} />
      <Route path="/c/:conversation_id" component={Chat} />
      <Route path="/c/" component={Chat} />
      <Route path="/FAQ" component={FAQ} />
      <Route path="/d/" component={Dashboard} />
      <Route path="/Success/:quoId" component={PickSuccess} />
      <Route path="/PaySuccess/:quoId" component={PaySuccess} />
      <Route path="/outreach-pay/:quo_id" component={OutreachCheckoutProvider} />
      <Route path="/pay/:quo_id" component={CheckoutProvider} />
      <Route path="/p" component={Profile} />
      <Route path="/" component={OpenCall} />
    </Switch>
  );
};


const LayoutRender = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    isFetching,
    authenticated,
    refreshStatus,
    error,
  } = useSelector(state => state.auth);

  const {
    action,
    role,
    quoId,
    type,
  } = useSelector(state => state.redirect);

  const {
    userData,
  } = useSelector(state => state.user);
  const {
    langCode,
  } = useSelector(state => state.ui);
  const history = useHistory();
  // if (userData.length === 0 && !isFetching && !error) {
  //   return (
  //     <Redirect to="/log_in" />
  //   );
  // }


  if (action === "redirect login" && type === "outreach" && role === "buyer") {
    dispatch(loggingOutreachPayment());
    return (
      <Redirect to={`/outreach-pay/${quoId}?exlogin`} />
    );
  }

  if (action === "redirect login" && type === "outreach" && role === "creator") {
    dispatch(loggingOutreachPayment());
    return (
      <Redirect to={`/iq/${quoId}?exlogin`} />
    );
  }

  if (action === "redirect login" && type === "payment" && role === "buyer") {
    dispatch(loggingOutreachPayment());
    return (
      <Redirect to={`/pay/${quoId}?exlogin`} />
    );
  }

  if (action === "redirect login" && type === "buyingselling" && role === "creator") {
    dispatch(loggingOutreachPayment());
    return (
      <Redirect to={`/opencall/${quoId}?exlogin`} />
    );
  }


  if (userData.id && !userData.username && !location.pathname.includes("pay") && !location.pathname.includes("PaySuccess")) {
    return (
      <Redirect to="/set_username" />
    );
  }

  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route path="/" component={Pages} />
      </div>
    </div>
  );
};



const LoginComponentRenderLogic = () => {
  const {
    isFetching,
    authenticated,
    refreshStatus,
    error,
  } = useSelector(state => state.auth);

  if (refreshStatus === "none") {
    return ("");
  }
  if (authenticated && refreshStatus === "profile") {
    return (<LayoutRender />);
  }

  return (<OpenCall />);
};


const Router = () => {
  const dispatch = useDispatch();
  const {
    modal
  } = useSelector(state => state.opencall);
  const {
    isFetching,
    authenticated,
    refreshStatus,
    error,
  } = useSelector(state => state.auth);

  const {
    action, type, quoId,
  } = useSelector(state => state.redirect);

  const {
    userData,
  } = useSelector(state => state.user);
  const {
    langCode, webtext, isAccepted18,
  } = useSelector(state => state.ui);
  const location = useLocation();


  useEffect(() => {
    if (langCode) {
      dispatch(apiCallBegan({
        axiosCall: getWebTextByLang,
        data: langCode,
        onSuccess: uiTextLoaded.type,
        onError: uiTextLoadError.type,
      }));
    }
  }, [langCode]);

  useEffect(() => {
    dispatch(apiCallBegan({
      axiosCall: refresh,
      onSuccess: authRefreshed.type,
      onError: authRefreshFailed.type,
    }));
  }, []);

  useEffect(() => {
    const saved = localStorage.getItem("18-accepted");
    if (saved === "true") {
      dispatch(accepted18());
    }
  }, []);

  useEffect(() => {
    if (userData) {
      if (userData.language) {
        if (userData.language !== langCode) {
          dispatch(changedLangCode(userData.language));
        }
      }
    }
  }, [userData.language]);

  return (
    <div className={classNames({ blurr: !isAccepted18 && (location.pathname.includes("/p/") || location.pathname === ("/")) })}>
      {(webtext.length > 0)
        ? (
          <MainWrapper>
            <CookieConsent
              location="bottom"
              buttonText={getTransText("cookie.button.understand")}
              cookieName="CookieSet"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              {getTransText("We use cookies to give you the best experience and to ensure the safety of our users.")}
              {getTransText("We do not track you across other sites. You can see our")}
              <Link to="/cookie-policy" target="_blank" rel="noopener noreferrer"> {getTransText("Cookie Policy")} </Link>
              {getTransText("here, and our")}
              <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer"> {getTransText("Privacy Notice")} </Link>
              {getTransText("here.")}
            </CookieConsent>
            {!authenticated && (
              <div className="layout">
                <TopbarNoAuth />
              </div>
            )}
            {(authenticated && (location.pathname === "/selling" || location.pathname === "/buying"))
              && (
                <div className="layout">
                  <TopbarNoAuth />
                </div>
              )}
            <main>
              <Switch>
                <Route path="/exlogin/" component={RefreshTokenFromCookie} />
                {!authenticated && !modal && <Route exact path="/opencall/:quo_id" component={OpenDetailDirectPage} />}
                {!authenticated && <Route path="/opencall/demo/:page" component={OpenCall} />}
                {!authenticated && <Route path="/opencall" component={OpenCall} />}
                {!authenticated && <Route path="/GuestPaySuccess/:quoId" component={GuestPaySuccess} />}
                <Route path="/email-sent/:email" component={EmailSent} />
                <Route path="/logout">
                  {!authenticated && userData.id === null ? <Redirect to="/log_in" /> : <LogoutAuth />}
                </Route>
                <Route exact path="/log_in/iq/:quo_id">
                  {!authenticated && !userData.id ? <LogIn /> : <Redirect to="/" />}
                </Route>
                <Route exact path="/log_in/:reason">
                  {!authenticated && !userData.id ? <LogIn /> : <Redirect to="/" />}
                </Route>
                <Route exact path="/log_in">
                  {!authenticated && !userData.id ? <LogIn /> : <Redirect to="/" />}
                </Route>
                {!authenticated && !userData.id && <Route path="/p/:username" component={Profile} />}
                {!authenticated && <Route path="/outreach-pay/:quo_id" component={OutreachCheckoutProvider} />}
                {!authenticated && <Route path="/pay/:quo_id" component={CheckoutProvider} />}
                <Route exact path="/register/:username" component={Register} />
                <Route exact path="/register" component={Register} />
                {!authenticated && <Route exact path="/iq/:quoId" component={InvitingQuoForCreator} />}
                <Route exact path="/selling/" component={LandingSeller} />
                <Route exact path="/buying/" component={Landing} />
                <Route path="/reset" component={ResetPassword} />
                <Route path="/forget" component={ForgetPassword} />
                {!authenticated && <Route path="/FAQ" component={FAQ} />}
                <Route path="/RegSuccess/:email" component={RegSuccess} />
                <Route path="/RequestSuccess" component={RequestSuccess} />
                <Route path="/ResetSuccess" component={ResetSuccess} />
                <Route path="/example" component={Example} />
                <Route path="/404" component={ErrorPage} />
                <Route exact path="/terms-and-condition" component={Terms} />
                <Route exact path="/cookie-policy" component={CookiePolicy} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/set_username" component={SetUsername} />
                <Route exact path="/accept/:token" component={AcceptInvite} />
                <Route path="/">
                  <LoginComponentRenderLogic />
                </Route>
              </Switch>
            </main>
          </MainWrapper>
        ) : (
          <LoadingLogo />
        )}
    </div>
  );
};

export default Router;
